import './App.css'

import {ApolloProvider} from '@apollo/react-hooks'
import {StatusBar} from '@capacitor/status-bar'
import {ThemeProvider} from '@material-ui/core/styles'
import {setUser as setSentryUser} from '@sentry/react'
import {SafeArea} from 'capacitor-plugin-safe-area'
import {createBrowserHistory} from 'history'
import {FC, ReactNode, useContext, useEffect, useMemo, useState} from 'react'
import {
  Admin,
  Loading,
  Resource,
  RouteWithoutLayout,
  useGetIdentity,
  useLocale,
  useSetLocale,
} from 'react-admin'
import {Redirect, Route} from 'react-router-dom'

import AppFavicon from './components/AppFavicon'
import AppUpdateAvailableDialog from './components/AppUpdateAvailableDialog'
import Center from './components/Center'
import UnreadChatMessagesBrowserNotificationPresenter
  from './components/chat/UnreadChatMessagesBrowserNotificationPresenter'
import PushNotificationsHandler from './components/PushNotificationsHandler'
import {ApiUrlsContext, ApiUrlsProvider} from './contexts/ApiUrlsContext'
import {BrowserNotificationsProvider} from './contexts/BrowserNotificationsContext'
import {HistoryStackProvider} from './contexts/HistoryStackContext'
import {MobileKeyboardProvider} from './contexts/MobileKeyboardContext'
import SandboxProvider, {SandboxContext} from './contexts/SandboxContext'
import WorkerProvider from './contexts/WorkerContext'
import useAuthWatcher from './hooks/useAuthWatcher'
import useBaseHostname from './hooks/useBaseHostname'
import useGetAppUpdateInfo from './hooks/useGetAppUpdateInfo'
import useHasPermission from './hooks/useHasPermission'
import useMobileAppBadge from './hooks/useMobileAppBadge'
import useSessionMerchantUser from './hooks/useSessionMerchantUser'
import Layout from './Layout'
import CompanyDetailsPage from './pages/CompanyDetailsPage'
import ExternalChatPage from './pages/ExternalChatPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import InternalChatPage from './pages/InternalChatPage'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import NotFoundPage from './pages/NotFoundPage'
import ResetPasswordPage from './pages/ResetPasswordPage'
import SalesforceAuthenticationPage from './pages/SalesforceAuthenticationPage'
import SandboxPage from './pages/SandboxPage'
import SignupPage from './pages/SignupPage'
import StartExternalChatPage from './pages/StartExternalChatPage'
import ThreeSixtyChannelsConnectionPage from './pages/ThreeSixtyChannelsConnectionPage'
import {
  CampaignCreate,
  CampaignShow,
  CampaignsList,
} from './resources/campaignsResource'
import {ChannelEdit} from './resources/channelsResource'
import {
  ChatMessageTemplateCreate,
  ChatMessageTemplateShow,
  ChatMessageTemplatesList,
} from './resources/chatMessageTemplatesResource'
import {
  MerchantCustomerUserCreate,
  MerchantCustomerUsersEdit,
  MerchantCustomerUsersList,
} from './resources/merchantCustomerUsersResource'
import theme from './theme'
import apolloCache from './utils/apolloCache'
import authProvider from './utils/authProvider'
import buildDataProvider, {_apolloClient} from './utils/dataProvider'
import dataProviderCustomBuildFields from './utils/dataProviderCustomBuildFields'
import installDeepLinkHandler from './utils/deepLinkHandler'
import {isEmbedded} from './utils/embedded'
import i18nProvider, {DEFAULT_LANGUAGE, MESSAGES} from './utils/i18nProvider'
import {isNativeAndroidApp, isNativeMobileApp} from './utils/platform'
import {setTrackingEnabled, setUser, trackPageView} from './utils/tracking'


const App = () => {
  const {isAppUpdateAvailable, isImmediateUpdateAllowed} = useGetAppUpdateInfo()
  const baseHostname = useBaseHostname()
  useEffect(() => {
    if (!isNativeMobileApp) return
    (async () => {
      if (isNativeAndroidApp) {
        await StatusBar.setOverlaysWebView({overlay: true})
      }
      const {insets: {top}} = await SafeArea.getSafeAreaInsets()
      document.documentElement.style.setProperty('--safe-area-top-inset', `${top}px`)
    })()
  })
  useEffect(() => trackPageView(), [])
  if (isNativeMobileApp && isAppUpdateAvailable) return (
    <ThemeProvider theme={theme}>
      <AppUpdateAvailableDialog isImmediateUpdateAllowed={isImmediateUpdateAllowed} />
    </ThemeProvider>
  )
  return (
    <ApiUrlsProvider baseHostname={baseHostname}>
      <WorkerProvider>
        <AppResources />
      </WorkerProvider>
    </ApiUrlsProvider>
  )
}

const AppResources = () => {
  const [dataProvider, setDataProvider] = useState<any>(null)
  const {graphqlApiUrl, graphqlWebsocketApiUrl} = useContext(ApiUrlsContext)
  const history = useMemo(createBrowserHistory, [])
  useEffect(() => installDeepLinkHandler(history), [history])
  useEffect(() => history.listen(trackPageView), [history])
  useEffect(() => {
    const _buildDataProvider = async () => {
      const dataProvider = await buildDataProvider(
        {
          cache: apolloCache(), // TODO: Move directly into dataProvider.js
          getToken: async () => authProvider.getToken(),
          uri: graphqlApiUrl,
          websocketUri: graphqlWebsocketApiUrl,
        },
        {buildFields: dataProviderCustomBuildFields} // TODO: Move into dataProvider.js
      )
      setDataProvider(() => dataProvider)
    }
    _buildDataProvider()
  }, [graphqlApiUrl, graphqlWebsocketApiUrl])
  if (!dataProvider) return (
    <Center>
      <Loading
        loadingPrimary={MESSAGES[DEFAULT_LANGUAGE].ra.page.loading}
        loadingSecondary={null}
      />
    </Center>
  )
  return (
    <Admin
      authProvider={authProvider}
      catchAll={NotFoundPage}
      customRoutes={ROUTES}
      dataProvider={dataProvider}
      disableTelemetry
      history={history}
      i18nProvider={i18nProvider}
      layout={Content}
      loginPage={LoginPage}
    >
      <Resource name="aggregated_merchant_customer_user_tags" />
      <Resource
        create={withPermissionsCheck(CampaignCreate, 'create', 'campaigns')}
        list={CampaignsList}
        name="campaigns"
        show={CampaignShow}
      />
      <Resource edit={ChannelEdit} name="channels" />
      <Resource name="channel_chat_bot_users" />
      <Resource name="channel_merchant_users" />
      <Resource name="channel_working_hours" />
      <Resource
        create={withPermissionsCheck(
          ChatMessageTemplateCreate, 'create', 'chat_message_templates'
        )}
        list={ChatMessageTemplatesList}
        name="chat_message_templates"
        show={ChatMessageTemplateShow}
      />
      <Resource name="customer_users" />
      <Resource name="file_mime_types" />
      <Resource
        create={withPermissionsCheck(
          MerchantCustomerUserCreate, 'create', 'merchant_customer_users'
        )}
        edit={MerchantCustomerUsersEdit}
        list={MerchantCustomerUsersList}
        name="merchant_customer_users"
      />
      <Resource name="merchant_customer_user_tags" />
      <Resource name="merchant_users" />
      <Resource name="merchants" />
      <Resource name="organization_merchant_users" />
      <Resource name="secondary_responsible_merchant_users" />
      <Resource name="tags" />
      <Resource name="users" />
      <Resource name="whatsapp_accounts" />
      <Resource name="whatsapp_message_templates" />
    </Admin>
  )
}

/* This component has two responsibilities:
 * (1) Wrapping the app layout with global context providers
 * (2) Adding other higher level app functionality such as checking the authentication
 *     status every time the app browser tab is activated
 */
const Content: FC<ContentProps> = ({children}) => {
  // Checks on every tab activation whether the authentication session is still valid
  useAuthWatcher()
  // TODO: See if we can get apolloClient from useDataProvider()
  return (
    <ApolloProvider client={_apolloClient}>
      <AppLanguageSetup />
      <TrackingSetup/>
      <AppFavicon />
      <MobileAppBadge />
      <PushNotificationsHandler>
        <MobileKeyboardProvider>
          <HistoryStackProvider>
            <SandboxProvider>
              <ThemeProvider theme={theme}>
                <BrowserNotificationsProvider
                  disabled={isEmbedded || isNativeMobileApp}
                  shouldShowUserPrompt
                >
                  {!isNativeMobileApp && (
                    <UnreadChatMessagesBrowserNotificationPresenter />
                  )}
                  <Layout>{children}</Layout>
                </BrowserNotificationsProvider>
              </ThemeProvider>
            </SandboxProvider>
          </HistoryStackProvider>
        </MobileKeyboardProvider>
      </PushNotificationsHandler>
    </ApolloProvider>
  )
}

const withPermissionsCheck = (Component, action, resource) =>
  props => useHasPermission(action, resource) ?
    <Component {...props} /> :
    <NotFoundPage />

const TrackingSetup = () => {
  const {identity: {id: userId, isImpersonatedUser, merchantId} = {}} = useGetIdentity()
  const {merchantUser: {emailAddress} = {}} = useSessionMerchantUser()
  useEffect(() => {
    if (!(userId && emailAddress)) return
    setSentryUser({id: userId as string})
    setTrackingEnabled(!(emailAddress.endsWith('@flinkit.de') || isImpersonatedUser))
    setUser(userId as string, emailAddress)
    return () => {
      setSentryUser({id: undefined})
      setUser(null, null)
      setTrackingEnabled(false)
    }
  }, [emailAddress, isImpersonatedUser, merchantId, userId])
  return null
}

const AppLanguageSetup = () => {
  const {merchantUser: {language} = {}} = useSessionMerchantUser()
  const locale = useLocale()
  const setLocale = useSetLocale()
  useEffect(() => {
    language && (language !== locale) && setLocale(language)
  }, [language, locale, setLocale])
  return null
}

const MobileAppBadge = () => {
  useMobileAppBadge()
  return null
}

const withSandboxOnly = Component => () => {
  const {isSandbox} = useContext(SandboxContext)
  return isSandbox ? <Component /> : <NotFoundPage />
}

const ROUTES = [
  <Route exact key="home" path="/"><Redirect to="/inbox" /></Route>,
  <Route
    component={ThreeSixtyChannelsConnectionPage}
    exact
    key="360dialog-channels-connection"
    path="/360dialog-channels-connection"
  />,
  <Route
    component={StartExternalChatPage}
    exact
    key="embeddable-chat-page"
    path="/embedded/chat/:customerUserPhoneNumber"
  />,
  <Route component={ExternalChatPage} exact key="inbox" path="/inbox"/>,
  <Route
    component={ExternalChatPage} exact key="inbox-channel" path="/inbox/:channelId"
  />,
  <Route
    component={ExternalChatPage} exact key="inbox-chat" path="/inbox/:channelId/:chatId"
  />,
  <Route component={InternalChatPage} exact key="internal-chat" path="/team-chat" />,
  <Route
    component={InternalChatPage} exact key="internal-chat" path="/team-chat/:chatId"
  />,
  <Route component={LogoutPage} exact key="logout" path="/logout" />,
  <Route
    component={SalesforceAuthenticationPage}
    exact
    key="salesforce-authentication"
    path="/salesforce-authentication"
  />,
  <Route
    component={StartExternalChatPage}
    exact
    key="start-chat"
    path="/start_chat/:customerUserPhoneNumber"
  />,
  <RouteWithoutLayout
    component={SignupPage}
    exact
    key="signup"
    noLayout
    path ="/signup"
  />,
  <RouteWithoutLayout
    component={() => (
      <ApolloProvider client={_apolloClient}>
        <ForgotPasswordPage />
      </ApolloProvider>
    )}
    exact
    key="forgot-password"
    noLayout
    path="/forgot-password"
  />,
  <RouteWithoutLayout
    component={() => (
      <ApolloProvider client={_apolloClient}>
        <ResetPasswordPage />
      </ApolloProvider>
    )}
    exact
    key="reset-password"
    noLayout
    path="/reset-password/:passwordResetCode"
  />,
  <RouteWithoutLayout
    component={() => (
      <ApolloProvider client={_apolloClient}>
        <AppLanguageSetup />
        <CompanyDetailsPage />
      </ApolloProvider>
    )}
    exact
    key="company-details"
    noLayout
    path="/company-details"
  />,
  <Route
    component={withSandboxOnly(SandboxPage)}
    exact
    key={"sandbox"}
    path="/sandbox"
  />,
]

interface ContentProps {
  children?: ReactNode
}

export default App
